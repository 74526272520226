var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mainBox", [
    _c(
      "div",
      { staticClass: "wapBox" },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "eBtns" },
            [
              _c(
                "el-button",
                {
                  staticClass: "b_it",
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.tbRowAdd()
                    },
                  },
                },
                [_vm._v("新建组合")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tbBox" },
            [
              _c("TTable2", {
                ref: "myTable",
                attrs: {
                  tbloading: _vm.tbloading,
                  border: false,
                  showQuery: _vm.rowQuery,
                  tableData: _vm.tableData,
                  tableTitle: _vm.tableTitle,
                },
                on: { rowSeach: _vm.rowSeach },
                scopedSlots: _vm._u([
                  {
                    key: "rowStatus",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            disabled: scope.row.isDefault == "Y",
                            "active-color": "#00CE6F",
                            "inactive-color": "#ccc",
                            "active-value": "Y",
                            "inactive-value": "N",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeStatus(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.isDefault,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "isDefault", $$v)
                            },
                            expression: "scope.row.isDefault",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "operating",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.tbRowEdit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.tbRowDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("TPagination", {
                on: { initTable: _vm.initTable },
                model: {
                  value: _vm.tableParams,
                  callback: function ($$v) {
                    _vm.tableParams = $$v
                  },
                  expression: "tableParams",
                },
              }),
            ],
            1
          ),
        ]),
        _c("addOrEdit", {
          attrs: { isOpen: _vm.addModel.open, dataId: _vm.addModel.editRowId },
          on: { close: _vm.closeDialog },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }