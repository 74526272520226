<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' :title="dataId?'编辑组合':'添加组合'" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="728px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'>
					<template slot="positionTree" slot-scope="scope">
							123
					</template>
				
				</TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 提交</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String,
				default: '',
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{
					projectId:'',
	  			},
	  			isHttping:false,
	  			formlist:[
	  				{name: '组合名称',keys: 'configName',value: '',type: 'input',flex:12,isMust:true,},
	  				{name: '告警类型',keys: 'alarmType',value: '',type: 'select',flex:12,isMust:true,options: [{
							name: "运行",
							code: "run",
						},
						{
							name: "故障",
							code: "fault",
						},
					],},
	  				{name: '所属项目',keys: 'projectId',value: '',type: 'select',options:[],flex:12,isMust:true,changMethod:this.changeProject},
	  				{name: '设备类型',keys: 'categoryId',value: '',type: 'select',options:[],flex:12,isMust:true},
	  				{name: '工单模版',keys: 'configIdList',value: [],type: 'select',multiple:true,options:[],flex:24,isMust:true,noTags:true},
	  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			this.resetTFrom();
			this.initProjectArrs();
			this.initSystemClassify();
		},
		mounted() {},
		updated() {},
		beforeDestroy() {},
		methods: {
			changeProject(val,it){
				console.log(val,it)
				//项目切换
				this.htmlDatas.configIdList=[];
				this.initOrderTempArrs();
				 
			},
			async initOrderTempArrs(){
				let params={
					projectId:this.htmlDatas.projectId
				}
				let res=await this.ApiHttp('/order/tenant/orderConfig/getConfigListForCombination',params,'get');
				res.map(it=>{
					it.name = it.configName;
					it.code = it.orderConfigId;
				})
				this.common.insertOptions(this.formlist,'configIdList',res);
			},
			async initSystemClassify(){
				let params={
					isloading:false
				}
				let res=await this.ApiHttp('/base/tenant/category/list',params,'get');
				res.map(it=>{
					it.name = it.categoryName;
					it.code = it.categoryId;
				})
				console.log('999999999',JSON.parse(JSON.stringify(res)))
				this.common.insertOptions(this.formlist,'categoryId',res);
			},
			async initProjectArrs(){
				let res =await this.$store.dispatch('getAllProjects');
				if(res){
  				res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);
				}
				
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
	  		
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			async getDatasById(){
				if(this.dataId){
		  			let res = await this.ApiHttp('/order/tenant/orderConfig/getConfigCombination?configCombinationId='+this.dataId);
					if(res){
						this.htmlDatas={...this.htmlDatas,...res};
						this.initOrderTempArrs();
						
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
				}
				let urlType='/order/tenant/orderConfig/createConfigCombination'
				if(this.dataId){
					urlType='/order/tenant/orderConfig/updateConfigCombination ';//编辑
				}
				let res = await this.ApiHttp(urlType,params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>