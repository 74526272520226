<template>
	<!--组合工单-->
	<mainBox >
		<div class="wapBox">
			<div >
				<div class="eBtns">
					<el-button class='b_it' @click='tbRowAdd()' type="primary" >新建组合</el-button>
				</div>
				<div class="tbBox ">
					<TTable2 class='' ref="myTable" :tbloading='tbloading' :border='false' :showQuery='rowQuery' @rowSeach='rowSeach' :tableData="tableData" :tableTitle="tableTitle">
						<template slot="rowStatus" slot-scope="scope">
							<el-switch v-model="scope.row.isDefault" 
								:disabled='scope.row.isDefault=="Y"' 
								 active-color="#00CE6F" inactive-color="#ccc" active-value='Y' inactive-value='N' @change='changeStatus(scope.row)'></el-switch>
						</template>
						<template slot='operating' slot-scope="scope">
							<el-button type="text" @click.stop="tbRowEdit(scope.row)" >编辑</el-button>
							<el-button type="text" @click.stop="tbRowDel(scope.row)" >删除</el-button>
						</template>
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
			
		   <addOrEdit  :isOpen='addModel.open' @close='closeDialog' :dataId='addModel.editRowId' />
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import addOrEdit from './model/addOrEdit.vue'
	const statusArrs=[
		//入驻状态 0待审核 1待退租 2待回执 3待结算 4已退租 5已驳回
		{label:'闲置',value:1},
		{label:'使用中 ',value:2},
	]
	export default {
		mixins: [pagination, seachAndTable],
		components: {TTable2,TPagination,addOrEdit},
		data() {
			return {
				tableTitle: [
					{
						name: '组合名称',
						prop: "configName",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '所属项目',
						prop: "projectName",
					},
					{
						name: '设备类型',
						prop: "categoryName",
					},
					{
						name: '告警类型',
						prop: "alarmTypeStr",
					},
					{
						name: '工单模版',
						prop: "tempNames",
					},
					{
						name: '创建时间',
						prop: "creationDateStr",
					},
					{name:'规则状态',prop:'isDefault',slotName:"rowStatus",},
					{
						name: '操作',
						width: "180",
						fixed: 'right',
						slotName: "operating",
					},
				],
				tableData: [],
				addModel:{//新增，编辑的弹框信息
		      		open:false,
		      		editRowId:'',
		      	},
				
			};
			},
			watch: {},
				created() {
				},
				mounted() {
					this.showRowQuery();
				},
				computed: {},
				methods: {
					changeStatus(row){
						console.log(row.isDefault);
						if(row.isDefault){
							this.$confirm('请确认，是否要切换组合的默认开关？', '提示',{type:'warning'}).then(() => {
								let params={
								 	"configCombinationId":row.configCombinationId,
								}
								this.ApiHttp('/order/tenant/orderConfig/updateIsDefault',params,'post').then(res=>{
									this.initTable();
									this.$message({
							            type: 'success',
							            message: '操作成功'
						          	});
								});
							}).catch(() => {
								row.isDefault='N';
							});
						}
						
//						this.$confirm(
//				            "移除当前考勤组的员工，员工将自动进入【默认考勤组】，系统将删除该员工当前时间之后的班次，并继承【默认考勤组】班次，是否继续执行该操作？",
//				            this.$t("commons.warning"),
//				            {
//				              confirmButtonText: this.$t("commons.confirm"),
//				              cancelButtonText: this.$t("commons.cancel"),
//				              type: "warning",
//				            }
//			         	)	
						
						
					},
					closeDialog(isInit){
						//关闭弹框
						this.addModel={
				      		open:false,
				      		editRowId:'',
				      	};
				      	if(isInit){
				      		this.initTable();
				      	}
					},
					async initTable() {
						let params = {
							current: this.tableParams.current,
							rowCount: this.tableParams.rowCount,
							...this.rowFilterData,
						}
						for(let keys in params){
							if(!params[keys]) delete params[keys];
						}
						let res = await this.ApiHttp('/order/tenant/orderConfig/getConfigCombinationList', params);
						const sObj={
							run:'运行',
							fault:'故障',
						}
						res.rows.map(it=>{
							it.creationDateStr=this.common.getTimeMmss(it.creationDate)
							it.alarmTypeStr=sObj[it.alarmType];
							it.tempNames='';
							it.orderConfigClientVOList.map(_t=>it.tempNames+=_t.configName+';')
							
						})
						this.tableData = res.rows||[];
						this.tableParams.total = res.total;
						
						
						this.$nextTick(() => {
							this.tbloading = false;
						})
					},
					tbRowAdd(){
						this.addModel={
				      		open:true,
				      		editRowId:'',
				      	};
					},
					tbRowEdit(row){
						this.addModel={
				      		open:true,
				      		editRowId:row.configCombinationId+'',
				      	};
					},
					tbRowDel(row) {
						this.$confirm('是否确认删除', '提示').then(() => {
							let params={
								configCombinationId:row.configCombinationId
							}
							this.ApiHttp('/order/tenant/orderConfig/deleteConfigCombination?configCombinationId='+row.configCombinationId, {}, 'put').then(res => {
								if(res) {
									this.$message({type:'success',message:'删除成功'})
									this.initTable();
								}
							});

						}).catch(() => {});
					},

	    			

				},
			};</script>

<style scoped lang="scss">
	.wapBox {
		height: 100%;
		.flexs {
			height: 100%;
		}

		.right {
			padding: 16px 24px;
			.eBtns {
				position: relative;
				.el-button {
					padding: 10px 18px;
				}
				.sxBtn{
					position: absolute;
					right: 0;
				}
			}
		}
		.txColor0{
	    padding: 0 6px;
	   	color: #4e78ff;
    	background: #f4f6fe;
		}
		.txColor4{
	    padding: 0 6px;
	   	color: #78d549;
    	background: #f2faee;
		}
		.txColor5{
	    padding: 0 6px;
	   	color: #e8331c;
  		background: #fef5f4;
		}
	}
</style>
<style type="text/css">
	.tbTreeCheck thead .el-checkbox {
		display: none;
	}
	
	.materielBox .m-body {
		padding: 0 !important;
	}
	.dfNode1 {
		.el-tree-node__label {
			color: #1A4CEC;
		}
	}
	
	.drBtn.el-button--text {
		color: #2F3941 !important;
	}
</style>