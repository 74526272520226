var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "Y-tips" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "diyModel",
          attrs: {
            title: _vm.dataId ? "编辑组合" : "添加组合",
            visible: _vm.$attrs.isOpen,
            "close-on-click-modal": false,
            width: "728px",
            "before-close": _vm.closeMypup,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.$attrs, "isOpen", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("TForm", {
                ref: "htmlFrom",
                attrs: {
                  lableRow: "",
                  autoCheck: true,
                  labelPosition: "left",
                  model: _vm.htmlDatas,
                  formlist: _vm.formlist,
                  "label-width": "88px",
                  rowGutter: 16,
                },
                scopedSlots: _vm._u([
                  {
                    key: "positionTree",
                    fn: function (scope) {
                      return [_vm._v("\n\t\t\t\t\t\t123\n\t\t\t\t")]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "btmBtn" },
                [
                  _c(
                    "el-button",
                    { staticClass: "searchbtn", on: { click: _vm.closeMypup } },
                    [_vm._v(" 取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isHttping },
                      on: { click: _vm.validateFroms },
                    },
                    [_vm._v(" 提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }